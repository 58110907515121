import logo from "../assets/logo/logo.png"
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function Header(props) {
    return (
        <motion.div className="header"
            initial={{
                background: props.background,
            }}
        >
            <div className="header-container">
                <Link to="/"><div className="header-logo"><img src={logo} /></div></Link>
            </div>   
        </motion.div>
    );
}