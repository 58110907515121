import Footer from "../../components/footer";
import Header from "../../components/header";
import Cookies from "../../components/cookies";
import { useEffect } from "react";

export default function Terms() {

    useEffect(() => {
        document.title = 'Terms of Service - SpotUp';
      }, []);

    return (
        <div className="terms">
            <Header background={"rgba(84,72,238,1)"} />
            <div className="legal">
            <div className="legal-section">
                    <h1>Terms of Service</h1>
                    <p><b>Last Updated: Dec 18, 2023</b></p>
                    <p>This Terms of Service Agreement is part of the User Agreement which also comprises our Privacy Policy. If anything is unclear, or you don’t understand what we’re saying – don’t hesitate to reach out. Our goal is to maximize transparency and understanding about how SpotUp works.</p>
                    <p>We can't make read it but please do so before using our Services.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">1. Who can use our Services</div>
                    <p>Our Services means all aspects of the SpotUp mobile application, our website (https://spotup-app.com/) and the software and systems powering the applications and our website.</p>
                    <p>To use our services you have to must be a real human. Our services should not be used by AI or bots. You have to be at least 13 years old and depending on where you live, if you are under the age of consent in your country, a parent or guardian may also have to provide consent for you to use our Services. If you are not creating an account for yourself, you got to have legal authority to create an account on behalf of another person, company, organization or other legal entity.</p>
                    <p>All references to “you” and “your” in the User Agreement will mean both you, the end user and/or for accounts created on behalf of another person, company, organization, government or other legal entity, the person who has legal authority to create and/or operate the account. </p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">2. Data Protection and Privacy</div>
                    <p>To view information about what data you share with us, when it is shared and how we handle it when you use our Services, please look at our Privacy Policy. By using our Services you understand and consent that we must collect, use, store, process, share, and transfer this information to partners who help us provide our Services.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">3. Content</div>
                    <p>Content means profile photos, photos, emojis or replies shared through our Services.</p>
                    <p>You are responsible for the Content you share through our Services including ensuring that you have the right to use the Contetn, and that it complies with laws, rules, regulations and the User Agreement.</p>
                    <p>If you share Content that you do not have the right to use or that violates laws, rules, regulations or the User Agreement, we have the right to remove it and we will remove it without accepting any complaints.</p>
                    <p>We do not tolerate harassment, intimidation, defamation, threats, hateful content, child sexual exploitation, sexual abuse, promotion of suicide or self harm, intellectual property theft, or other illegal activities.</p>
                    <p>You own the Content you share through our Services and grant SpotUp a license to use the Content under the following conditions:</p>
                    <ul>
                        <li>Friends Only Content means Content you shared only with your friends on SpotUp. On a voluntary basis, some people may choose to share their Friend Only Content with SpotUp so that it can be used for communication, commercial and marketing purposes. For instance on billboards, other advertising, Apple's App Store or Google's Play Store. If you decide to do that you will need to give SpotUp a license to use your Friends Only Content.</li>
                        <li>Globally Shared Content means Content you shared with everyone on SpotUp. In exchange for using our Services, you grant us a worldwide, non-exclusive, royalty-free, sublicensable license to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute the content you share with a Globally Shared Content in any and all media or distribution methods. This license authorizes us to make your Globally Shared Content available to the rest of the world and to let others do the same. You agree that this license includes the right for SpotUp to provide, promote, and improve our Services and to make Globally Shared Content available to other companies, organizations or individuals for the syndication, broadcast, distribution, promotion or publication on other media and services. Such additional uses by SpotUp, or other companies, organizations or individuals, is made with no compensation paid to you with respect to the Globally Shared Content as the use of our Services by you is hereby agreed as being sufficient compensation.</li>
                    </ul>
                </div>
                <div className="legal-section">
                    <div className="legal-title">4. Reporting Violative Content</div>
                    <p>We are not under an obligation to monitor the Content people share through our Services.  Nevertheless, we want our Services to be a place where people can have fun and express their real selves while feeling safe. Content that violates another person’s rights, or does not comply with laws, rules, regulations or the User Agreement (“Violative Content”) is prohibited. If you see Violative Content, you can report it to us through the reporting feature in the application.</p>
                    <p>If you believe your Content has been copied in a way that infringes your intellectual property rights, you can report this directly in the application.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">5. Ending our Services</div>
                    <p>We may temporarily or permanently suspend your account and prevent your use of our Services if we have determined that you have shared Violative Content or used our Services in a way that violates another person’s rights, or does not comply with laws, rules, regulations or the User Agreement.</p>
                    <p>To keep our platform safe we will permanently suspend you from our Services immediatly and prevent you from creating a new SpotUp account.</p>
                    <p>You may appeal a suspension by contacting us through email via info@spotup-app.com</p>
                    <p>We will delete your account if it is inactive for more than 2 years. Before we do, we will let you know so that you can stop the deletion if you want.</p>
                    <p>You can delete your account at any time by going into the account settings in the application and selecting the account deletion option. Be aware that you can't undo an account deletion.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">6. License to use our Services</div>
                    <p>Our Services are protected by copyright, trademark, trade secret and other laws around the world. As tempting as it may be, nothing in the User Agreement gives you a right to use the SpotUp name or any of the SpotUp trademarks, logos, domain names, other distinctive brand features, and other proprietary rights. </p>
                    <p>During the time you are using our Services, SpotUp gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you as part of our Services. This license has the sole purpose of enabling you to use and enjoy our Services in a manner consistent with the User Agreement. This license comes to an end when you stop using our Services.</p>
                    <p>SpotUp is free to use any feedback, comments or suggestions you may provide regarding our Services.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">7. Limitation of Liability</div>
                    <p>By using our Services you agree that SpotUp, its affiliates, related companies, officers, directors, employees, agents representatives, partners and licensors, liability is limited to the maximum extent permissible in your country of residence.</p>    
                </div>
                <div className="legal-section">
                    <div className="legal-title">8. General</div>
                    <p>SpotUp may update the User Agreement at any time. If we make an update that impacts either parties rights or obligations, we will let you know approximately 30 days before the update goes into effect. If you keep using our services after the 30 days, you are agreeing to our updated User Agreement. If you do not want to agree, you must stop using our Services.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">9. Contact or Questions</div>
                    <p>Hearing from you makes us and our Services better. Whether you have a question about any part of the User Agreement, a comment or feedback about our Services, we want to hear from you. </p>
                    <p>You can reach us by postal mail at:</p>
                    <p>Address</p>
                    <p>You can also contact us by email via info@spotup-app.com</p>
                </div>
            </div>
            <Footer background={"rgba(84,72,238,1)"} />
            <Cookies />
        </div>
    )
}