import { Link } from "react-router-dom"
import { motion } from "framer-motion"

import instagram from "../assets/socials/instagram.png"
import twitter from "../assets/socials/twitter.png"
import youtube from "../assets/socials/youtube.png"
import tiktok from "../assets/socials/tiktok.png"

export default function Footer(props) {
    return (
        <motion.div className="footer"
            initial={{
                background: props.background,
            }}
        >
            <div className="footer-container">
                    <div className="footer-menu">
                        <Link to="/legal/terms"><div className="footer-menu-item">Terms of Service</div></Link>
                        <Link to="/legal/privacy"><div className="footer-menu-item">Privacy Policy</div></Link>
                        <Link to="/legal/imprint"><div className="footer-menu-item">Imprint</div></Link>
                    </div>
                    <div className="footer-socials">
                        <div className="footer-socials-icon"><img src={instagram} onClick={() => window.open("https://www.instagram.com/spotup.app/", '_blank')} /></div>
                        <div className="footer-socials-icon"><img src={twitter}/></div>
                        <div className="footer-socials-icon"><img src={tiktok}/></div>
                    </div>
            </div>            
        </motion.div>
    );
}