export default function TextField(props) {

    const text = props.text;
    const setText = props.setText;

    return (
        <div className="textfield">
            <div className="textfield-title">
                {props.title}
            </div>
            <div className="textfield-input">
            <input type={props.secure ? "password" : "text"} value={text} onChange={(e) => setText(e.target.value)} />
            </div>
        </div>
    );
}