import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Start from './pages/start/start';
import Cookies from './components/cookies';
import Terms from './pages/legal/terms';
import Privacy from './pages/legal/privacy';
import ResetPassword from './pages/auth/reset-password';
import Imprint from './pages/legal/imprint';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Start />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="legal/">
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="imprint" element={<Imprint />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
