import Footer from "../../components/footer";
import Header from "../../components/header";
import Cookies from "../../components/cookies";
import { useEffect } from "react";

export default function Privacy() {

    useEffect(() => {
        document.title = 'Privacy Policy - SpotUp';
      }, []);

    return (
        <div className="privacy">
            <Header background={"rgba(84,72,238,1)"} />
            <div className="legal">
                <div className="legal-section">
                    <h1>Privacy Policy</h1>
                    <p><b>Last Updated: Dec 18, 2023</b></p>
                    <p>This Privacy Policy is part of the User Agreement which also comprises our Terms of Service. It's designed to make sure you know what information we collect about you, how we use it, when we share it, and the controls we provide you to manage the information you share with us.</p>
                    <p>We can't make read it but please do so before using our Services.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">1. Information we receive about you</div>
                    <p>When we use the term Information we mean data that may include but not be limited to personal data.</p>
                    <p>SpotUp collects information that you provide directly to us (data that you provide to us) and also through your use of our Services (data we collect when you use our Services).</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">1.1 Data that you provide to us</div>
                    <p><b>Account Creation Data:</b> This is data that is required to create an account. Without it, we cannot provide you our Services.</p>
                    <ul><li>your email, first and/or last name, date of birth, and a username</li></ul>
                    <p>After account creation there is also some additional information you can decide to share with us.</p>
                    <ul><li>a profile picture</li></ul>
                    <p><b>Content Data:</b> We receive Content (as defined in the Terms) that you create when you use our Services. This includes:</p>
                    <ul><li>profile picture, photos, emojis, replies and your geolocation</li></ul>
                    <p>Remember, other users can copy (e.g., screenshot) your Content and share it through other services so we recommend that you do not share any Content that you do not want other people see or copy.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">1.2 Data we collect when you use our Services</div>
                    <p>Like all internet based services, as you use our Services, we automatically receive information about you from your device and your usage of our Services</p>
                    <p><b>Connection and Technical Data:</b></p>
                    <ul><li>IP address, account creation data</li></ul>
                    <p><b>Interaction Data:</b></p>
                    <ul><li>friends, number of friends, replies you leave on Content shared by others, the friends with whom you interact the most, functional data</li></ul>
                    <p><b>Usage Data:</b> actions performed, time of posting, content viewed, content interactions, deletions, blocking, application crashes and event logs.</p>
                    <p><b>Device and Equipment Data:</b> device type, operating system, language preference</p>
                    <p>We also receive data when you decide at your discretion to use certain features of our Services:</p>
                    <p><b>Camera and Photos Library:</b> You can create an account without allowing us to access and collect data from your device's camera or photo library. If you refuse to allow us this access, you will not be able to share Content on our Services. It's a foundational part of how SpotUp works.</p>
                    <p><b>Geolocation Data:</b> You can decide to share your location through our Services. Geolocation data is foundational to the use of our Services. You can't share stories, tag friends and get points and badges without allowing us to access your location. However, we will only store your location whenever you post a story to connect you to a spot or to show friends where they're tagged.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">2. How we use information we receive</div>
                    <p>We would not want anyone selling our information so we do not sell your information. We use your information carefully and thoughtfully to bring you our Services. </p>
                    <p>Our Services are brought to you through a variety of software and technical infrastructure. Understanding how these systems work is complicated and they are constantly evolving as we improve our Services. Information is often used by multiple parts of the systems to bring you even just a small feature. As a general rule the use cases SpotUp makes of the information we receive falls into the following categories:</p>
                    <p><b>Operation and Improvement:</b> To create and account for you, receive and display Content, ensure our Services operate as you expect, and improve them.</p>
                    <p><b>Safety and Security:</b> Ensure your and other users safety and security, the security of our Services, our partners, and compliance with relevant laws and regulations.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">3. When we share information about you</div>
                    <p>We only share your information when it's necessary to provide you our Services or it's required by law. That generally falls into the following categories.</p>
                    <p><b>Users:</b> When you share Content through our Services, that Content is available to your real friends or if you share the Content more broadly to all SpotUp users. </p>
                    <p><b>Service Providers:</b> To bring you our Services we work with a small group of partners who provide us services. This includes partners who help with technical and hosting support, sending notifications, publishing, and sharing content, user analytics and satisfaction surveys, management of security incidents or fraudulent activity, etc. In working with those partners, we may share your information with them to bring you our Services. </p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">4. How long do we keep information about you</div>
                    <p>Our goal is to keep your information only for as long as it's needed to provide you our Services. Sometimes legal requirements mean that we have to keep your information for longer than our stated practices. Generally we keep your information according to the timelines below. </p>
                    <p><b>Content data:</b> retained indefinitely or until the Content is deleted by you.</p>
                    <p><b>Interaction data:</b> retained indefinitely or until the interaction is deleted by you.</p>
                    <p><b>Camera and Photo library:</b> Content data is retained indefinitely (i.e., as long as you have an active account) or until the Content is deleted by you. Remember we don't upload all of your photos, you choose what Content you upload and share.</p>
                    <p><b>Geolocation Data:</b> When you share your Geolocation data with us as part of your Content, the information is retained for as long as the Content remains on our system.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">5. What controls do you have over your information</div>
                    <p><b>Notification:</b> In the settings of the applications you can choose and control which notifications you receive about our Services.</p>
                    <p><b>Block Users:</b> To make sure you have a great experience when using our Services, you can block users who you do not want to receive Content from. If you block a user, at any time you can go into the settings of applications and view who you have blocked. You can also unblock them at any time.</p>
                    <p><b>Delete Your Account:</b> Int the settings of the applications you can choose to delete your account at any time. Your account and all of your information will be permanantly deleted.</p>
                </div>
                <div className="legal-section">
                    <div className="legal-title">6. What rights do you have over your information</div>
                    <ul>
                        <li>Delete Your Account through the settings in the applications</li>
                        <li>Understand who we share your data with by reading this list of service providers</li>
                        <li>Correct and edit your information by using the controls in the application</li>
                        <li>Ask a question about our privacy practices by contacting privacy support</li>
                    </ul>
                </div>
                <div className="legal-section">
                    <div className="legal-title">7. Service Providers</div>
                    <ul>
                        <li><b>Firebase/Google Cloud:</b> User authentication, store user data, store profile pictures, store story images and data, send push notifications, website hosting</li>
                        <li><b>Mapbox:</b> Map</li>
                    </ul>
                </div>
                <div className="legal-section">
                    <div className="legal-title">8. Contact</div>
                    <p>If you have a question about our Privacy Policy, you can reach us by postal mail at:</p>
                    <p>Address</p>
                    <p>You can also contact us by email via info@spotup-app.com</p>
                </div>
            </div>
            <Footer background={"rgba(84,72,238,1)"} />
            <Cookies />
        </div>
    )
}