import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyC51jFh09a-cfP78puu7tAodpGle0op8JA",
    authDomain: "spotup-8bbc1.firebaseapp.com",
    projectId: "spotup-8bbc1",
    storageBucket: "spotup-8bbc1.appspot.com",
    messagingSenderId: "881864340277",
    appId: "1:881864340277:web:0c5717472bb9399d07927c",
    measurementId: "G-V9BBZYFRT9"
};

const firebase = initializeApp(config);
const analytics = getAnalytics(firebase);
const auth = getAuth(firebase)
const firestore = getFirestore(firebase);

export { firebase, analytics, auth, firestore };