import Footer from "../../components/footer";
import Header from "../../components/header";
import Cookies from "../../components/cookies";
import { useEffect } from "react";

export default function Imprint() {

    useEffect(() => {
        document.title = 'Imprint - SpotUp';
      }, []);
    
    return (<div>
        <Header background={"rgba(84,72,238,1)"} />
        <div className="legal">
            <div className="legal-section">
                <h1>Imprint</h1>
            </div>
        </div>
        <Footer background={"rgba(84,72,238,1)"} />
    </div>)
}