import Footer from "../../components/footer";
import Header from "../../components/header";
import { useEffect, useState } from "react";
import TextField from "../../components/textfield";
import { auth } from "../../lib/firebase";
import { confirmPasswordReset } from "firebase/auth"
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export default function ResetPassword() {

    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("oobCode");

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    const requirements = {
        "8-32 characters": password.length >= 8 && password.length <= 32,
        "At least 1 lowercase letter, 1 uppercase letter and 1 number": /.*[a-z].*/.test(password) && /.*[A-Z].*/.test(password) && /.*[0-9].*/.test(password),
    }

    async function handleReset() {
        setIsLoading(true);
        confirmPasswordReset(auth, code, password)
            .then(function() {
                setSuccess(true);
            })
            .catch(function() {
                console.log("error");
            })
            
    }

    useEffect(() => {
        if (!code) {
            navigate("/");
        }
        document.title = 'Reset Password - Compete';
    }, []);

    return (
        <div>
            <Header />
            <div className="container">
                <div className="auth-section">
                    {success ? <p>Password reset succesful.</p> :
                        <div className="password-reset-form">
                            <TextField title="New Password" text={password} setText={setPassword} secure={true} />
                            <TextField title="Repeat New Password" text={password2} setText={setPassword2} secure={true} />
                            <motion.div className="button" onClick={() => {if(password === password2 && password.length >= 8) {handleReset()}}} disabled={password !== password2 || password.length < 8}
                                whileTap={{
                                    scale: password === password2 && password.length >= 8 ? 0.96 : 1,
                                }}
                                animate={{
                                    background: password === password2 && password.length >= 8 ? "rgba(84,72,238,1)" : "rgba(130,132,138,0.16)",
                                    color: password === password2 && password.length >= 8 ? "rgba(255,255,255,1)" : "rgba(130,132,138,1)",
                                }}
                            >
                                {isLoading ? <div>
                                    Resetting...
                                </div>:
                                <div>
                                    Confirm
                                </div>}
                            </motion.div>
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}