import web from "../../assets/assets/web-illu.png"
import mobile from "../../assets/assets/mobile-illu.png"
import tablet from "../../assets/assets/tablet-illu.png"
import star from "../../assets/assets/star.png"
import { useEffect } from "react";

import Header from "../../components/header"
import Footer from "../../components/footer"
import { motion } from "framer-motion"
import Cookies from "../../components/cookies"

export default function Start() {

    useEffect(() => {
        document.title = 'SpotUp';
      }, []);

    return (
        <div className="start">
            <Header />
            <div className="start-image">
                <img className="start-mobile" src={mobile} />
                <img className="start-tablet" src={tablet} />
                <img className="start-web" src={web} />
                <motion.div className="start-text"
                        initial={{
                            scale: 0,
                            opacity: 0
                        }}
                        animate={{
                            scale: 1,
                            opacity: 1
                        }}
                        transition={{
                            type: "spring",
                            duration: 1.0,
                            delay: 1.0
                        }}
                    >
                        <div className="start-heading">Coming Soon...</div>
                    </motion.div>
            </div>
            <Footer />
            <Cookies />
        </div>
    )
}