import { useState } from "react";
import { useEffect } from "react";
import { motion } from "framer-motion";

export default function Cookies() {

    const [accepted, setAccepted] = useState(false);

    function acceptPolicy() {
        sessionStorage.setItem('cookies', true);
        setAccepted(true);
    }

    useEffect(() => {
        if (window.sessionStorage.getItem('cookies')) {
            setAccepted(true);
        }
    }, []);

    return !accepted ? (
        <motion.div className="cookies"
            initial={{
                scale: 0.8,
                opacity: 0
            }}
            animate={{
                scale: 1.0,
                opacity: 1
            }}
            exit={{
                scale: 0.8,
                opacity: 0
            }}
            transition={{
                type: "linear",
                duration: 0.4,
                delay: 0.2
            }}
        >
            <div className="cookies-title">
                SpotUp uses Cookies
            </div>
            <p>
                This website is using cookies from third-party services that are necessary to run this website. We, SpotUp, are not collecting any other cookies that are unnecessary. You can view more information in the Privacy Policy.
            </p>
            <motion.div className="button"
                whileTap={{
                    scale: 0.96,
                }}
                onTap={() => acceptPolicy()}
            >
                Accept
            </motion.div>
        </motion.div>
    ) : (<div></div>);
}